import { differenceInYears } from 'date-fns';

export const DateUtil = {
  getCurrentDate: () => {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    return currentDate.getTime();
  },
  getDateTwoMonthsAgo: (policyExpiredDate) => {
    const expiredDate = new Date(policyExpiredDate);
    expiredDate.setHours(0, 0, 0, 0);
    const aWeekBeforeExpiredDate = new Date(expiredDate.setDate(expiredDate.getDate() - 60));
    return aWeekBeforeExpiredDate.getTime();
  },
	getTimeAgo: (date) => {
		const MINUTE = 60;
		const HOUR = MINUTE * 60;
		const DAY = HOUR * 24;

    const secondsAgo = Math.round((+new Date() - new Date(date)) / 1000);

    if (secondsAgo < MINUTE) {
      return `${secondsAgo} Detik`;
    } else if (secondsAgo < HOUR) {
      return `${Math.floor(secondsAgo / MINUTE)} Menit`;
    } else if (secondsAgo < DAY) {
      return `${Math.floor(secondsAgo / HOUR)} Jam`;
    } else {
      return `${Math.floor(secondsAgo / DAY)} Hari`;
    }
  },
  // date formate DD/MM/YYYY
  getAge: (date) => {
    const formattedDate = date.split('/').reverse().join('-');
    return differenceInYears(new Date(), new Date(formattedDate));
  },
  /**
  * Adds time to a date.
  * Example: dateAdd(new Date(), 'minute', 30)  //returns 30 minutes from now.
  * 
  * @param date  Date to start with
  * @param interval  One of: year, quarter, month, week, day, hour, minute, second
  * @param units  Number of units of the given interval to add.
  */
  dateAdd: (date, interval, units) => {
    if(!(date instanceof Date))
      return undefined;
    let formattedDate = new Date(date);
    const checkRollover = () => {
      if (formattedDate.getDate() != date.getDate()) formattedDate.setDate(0);
    };
    switch(String(interval).toLowerCase()) {
      case 'year'   :  formattedDate.setFullYear(formattedDate.getFullYear() + units); checkRollover();  break;
      case 'quarter':  formattedDate.setMonth(formattedDate.getMonth() + 3 * units); checkRollover();  break;
      case 'month'  :  formattedDate.setMonth(formattedDate.getMonth() + units); checkRollover();  break;
      case 'week'   :  formattedDate.setDate(formattedDate.getDate() + 7 * units);  break;
      case 'day'    :  formattedDate.setDate(formattedDate.getDate() + units);  break;
      case 'hour'   :  formattedDate.setTime(formattedDate.getTime() + units * 3600000);  break;
      case 'minute' :  formattedDate.setTime(formattedDate.getTime() + units * 60000);  break;
      case 'second' :  formattedDate.setTime(formattedDate.getTime() + units * 1000);  break;
      default       :  formattedDate = undefined;  break;
    }
    return formattedDate;
  },
  relativeDate: (date) => {
    const now = new Date().setHours(0, 0, 0, 0);
    const then = date.setHours(0, 0, 0, 0);
    const days = (then - now) / 86400000;
    let formattedRelativeDate;

    if (days === 0) {
      formattedRelativeDate = {
        text: 'Untuk Di follow-up hari ini',
        color: '#1ec494'
      };
    } else if (days === -1) {
      formattedRelativeDate = {
        text: 'Belum di follow-up dari kemarin',
        color: '#faaf40'
      };
    } else if (days <= -2 && days >= -10 ) {
      formattedRelativeDate = {
        text: 'Belum di follow-up dari 2 - 10 Hari',
        color: '#b03838'
      };
    } else if (days < -10) {
      formattedRelativeDate = {
        text: 'Belum di follow-up lebih dari 10 Hari',
        color: '#333333'
      };
    } else if (days > 0) {
      formattedRelativeDate = {
        text: 'Dijadwalkan Untuk Masa Depan',
        color: '#1d66dd'
      };
    }
    return formattedRelativeDate;
  }
};

export default { DateUtil };
